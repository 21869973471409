import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Spinner from '../Spinner';

const Query = ({ children, query, id }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id: id || 0 },
  });
  if (error) console.warn(error.message);
  //if (error) return <p>Error: {JSON.stringify(error)}</p>;
  if (loading && !data) return <Spinner />;
  if (!data) return <Spinner />;
  return children({ data });
};

export default Query;
