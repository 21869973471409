import React from "react";
import { Link } from "react-router-dom";

const ItemCard = ({ id, title, imageUrl }) => {
  return (
    <Link to={`/item/${id}`} className="uk-link-reset">
      <div className="uk-card uk-panel-box uk-card-large">
        <div
          style={{ height: 220, overflow: "hidden" }}
          className="uk-background-muted"
        >
          <img src={imageUrl} alt={imageUrl} style={{ marginTop: -10 }} />
        </div>
        <div className="uk-margin-top" style={{ height: 100 }}>
          <p id="category" className="uk-text-uppercase">
            {title}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ItemCard;
