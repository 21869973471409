import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { SERVERADDRESS } from "../constants/vhpt";
//const serverAddress = process.env.REACT_APP_BACKEND_URL;

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${SERVERADDRESS}/graphql`,
});
const client = new ApolloClient({
  cache,
  link,
});

export default client;
