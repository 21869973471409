import React from "react";
import Card from "../RecordCard";
import { imageURLForRecord } from "../../utils/recordUtils";

const Records = ({ records }) => {
  return (
    <div>
      <div
        className="uk-child-width-expand@s uk-text-center uk-grid-column-small"
        data-uk-grid
      >
        <div>
          <div
            className="uk-child-width-1-3@s uk-child-width-1-4@m"
            data-uk-grid
          >
            {records.map((record, i) => {
              let imageURL = imageURLForRecord(record);
              return <Card imageUrl={imageURL} title={record.title} link={`/record/${record.id}`} name={record.name} key={`record__${record.id}`} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Records;
