import gql from "graphql-tag";
import {
  realationalSearchType,
  realationalSearchName,
} from "../../constants/vhpt";

const ITEMS_QUERY = (start, limit, search, type) => {
  let searchStr = `start : ${start}, limit : ${limit}`;

  if (search.length >= 1) {
    let searchText = '"' + search.trim() + '"';

    if (realationalSearchType[type]) {
      searchStr += `,where : { ${realationalSearchName[type]} : {${realationalSearchType[type]} : ${searchText} }}`;
    } else {
      searchStr += `,where : { ${type}_contains : ${searchText} }`;
    }
  }
  //  console.log("search: ", searchStr);
  return gql`
  query Items {
    items(${searchStr}) {
        id
        name
        description
        website
        dimensions {
          id
          Dimension
        }
        copyrights {
          id
        }
        materials {
          id
        }
        scran_id
        locations {
          name
          description
        }
        people {
          id
        }
        records {
          id
          name
          title
        }
        image {
          url
          formats
        }
        materials { id type }
        periods { id date }
    }
  }
`;
};

export default ITEMS_QUERY;
