import React from "react";
import Card from "../DigitisedInventoriesCard";
//import Card from "../ItemCard";
import { smallestImageURLForRecord } from "../../utils/recordUtils";
const DigitisedInventoriesList = ({ records }) => {
  return (
    <div>
      <div
        className="uk-child-width-expand@s uk-text-center uk-grid-column-small"
        data-uk-grid
      >
        <div>
          <div
            className="uk-child-width-1-3@s uk-child-width-1-4@m"
            data-uk-grid
          >
            {records.map((record, i) => {
              const imageUrl = smallestImageURLForRecord(record);

              return (
                <Card
                  id={record.id}
                  title={record.title}
                  imageUrl={imageUrl}
                  key={`record__${i}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitisedInventoriesList;
