import React from "react";

import { Switch, Route } from "react-router-dom";

import Nav from "../../components/Nav";
import Item from "../Item";
import Items from "../Items";
import Records from "../Records";
import Record from "../Record";
import Home from "../Home";
import ImageView from "../ImageView";
import Copyright from "../Copyright";
import InventoryDigests from "../InventoryDigests";
import InventoryDigest from "../InventoryDigest";
import DigitisedInventories from "../DigitisedInventories";
import DigitisedInventory from "../DigitisedInventory";
import People from '../People';
import Person from '../Person';

function App() {

  return (
    <div className="App uk-responsive-width">
      <Nav />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/records" component={Records} exact />

        <Route path="/record/:id" component={Record} exact />
        <Route path="/inventory_digests" component={InventoryDigests} exact />
        <Route path="/inventory_digest/:id" component={InventoryDigest} exact />
        <Route
          path="/digitised_inventories"
          component={DigitisedInventories}
          exact
        />
        <Route
          path="/digitised_inventory/:id"
          component={DigitisedInventory}
          exact
        />
        <Route path="/item/:id" component={Item} exact />
        <Route path="/items" component={Items} exact />
        <Route path="/imageview/:id" component={ImageView} exact />
        <Route path="/copyright" component={Copyright} exact />
        <Route path="/people" component={People} exact />
        <Route path="/people/:id" component={Person} exact />
      </Switch>
    </div>
  );
}

export default App;
