import React from "react";
import { Link } from "react-router-dom";

const RecordCard = ({ title, id, imageUrl }) => {
  return (
    <Link to={`/digitised_inventory/${id}`} className="uk-link-reset">
      <div className="uk-panel-box">
        <div
          style={{ height: 50, overflow: "hidden", objectFit: "fill" }}
          className="uk-background-muted"
        >
          <img src={imageUrl} alt={imageUrl} />
        </div>
        <div className="uk-margin-top" style={{ height: 80 }}>
          <p id="category">{title}</p>
        </div>
      </div>
    </Link>
  );
};

export default RecordCard;
