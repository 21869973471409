import gql from "graphql-tag";

const INVENTORY_DIGEST_QUERY = gql`
  query InventoryDigest($id: ID!) {
    inventoryDigest(id: $id) {
      id
      title
      introduction
      information
      records { id title name }
      digitised_pages { id title pages { id title item {id title objetdart {id }} records { id title name image {
        url
        formats
      } } }}
      scran_id
      image {
        url
        formats
      }
    }
  }
`;

export default INVENTORY_DIGEST_QUERY;
