import React from "react";
import { Link } from "react-router-dom";

const RecordCard = ({ link, title, name, imageUrl, scran_id }) => {
  return (
    <Link to={link} className="uk-link-reset">
      <div className="uk-card uk-panel-box uk-card-large  ">
        {scran_id}
        <div className="uk-card-media-top">
          <img src={imageUrl} alt={imageUrl} height="100" width="200" />
        </div>
        <div className="uk-margin-top">
          <p id="title" className="uk-text-large">
            {title}
          </p>
          <p id="category" className="uk-text-uppercase">
            {name}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default RecordCard;
