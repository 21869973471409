import React from "react";

const Copyright = () => (
  <div className="uk-section">
    <div className="uk-container uk-container-small">
      <div className="uk-panel">
        <h1>Rights and VHPT</h1>
        <div>
          <p>This section explains your rights when using resources.</p>
          <h2>Rights</h2>
          <p>
            All materials are copyright. They belong to those listed in the
            database and are licensed through VHPT and Scran. The system design,
            interface and the database are copyright Scran.&nbsp; You may use
            materials because we have negotiated rights from the owners. There
            are things you can do and things you cannot do based on those
            rights.
          </p>
          <p>
            If you do not have a password, you have limited rights. If you have
            a password, you have more rights because you have entered an
            agreement with VHPT. This is a summary to help you understand these
            terms.
          </p>
          <h2>Using the Free Service</h2>
          <p>
            If you do not have a password or if the VHPT system only allows you
            to see small thumbnail images, you are using the free service.
          </p>
          <p>You are free to:</p>
          <ul>
            <li>Search the whole Database </li>
            <li>View thumbnail sized images only </li>
            <li>
              Download these images for private, personal, educational use{" "}
            </li>
          </ul>
          <p>
            You may <strong>not</strong>:
          </p>
          <ul>
            <li>
              Distribute these resources to anyone else in paper or electronic
              form - eg printouts, emails, web sites, CD or DVD ROMs or in
              presentations{" "}
            </li>
            <li>Make any money from these resources </li>
          </ul>
          <h2>Using the Password Service</h2>
          <p>
            If you have a password, you have these rights as long as you abide
            by the terms.
          </p>
          <p>You are free to:</p>
          <ul>
            <li>Search the whole database </li>
            <li>View images </li>
            <li>Use any&nbsp; provided tools </li>
          </ul>
          <p>
            You may <strong>not</strong>:
          </p>
          <ul>
            <li>
              Distribute these resources to any{" "}
              <span className="uk-text-bold">other</span> user in paper or
              electronic form - eg printouts, emails, web sites, CD or DVD ROMs
              or in electronic or paper presentations{" "}
            </li>
            <li>Make any money from these resources </li>
          </ul>
          <h2>Accreditation</h2>
          <p>
            Any reference to resources on this site must mention the Virtual
            Hamilton Palace Trust and accredit the resource appropriately. The
            proper form is:
          </p>
          <p className="uk-text-bold">(c) Name of owner / licensor VHPT</p>
          <p>
            The name of the owner is listed in each record. <br />
          </p>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
);

export default Copyright;
