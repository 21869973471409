import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Copyright from "../../components/Copyright";
import { smallestImageURLForRecord } from "../../utils/recordUtils";

//import DIGITISED_INVENTORY_QUERY from "../../queries/digitisedInventories/digitisedInventory";
import DIGITISED_INVENTORY_QUERY from "../../queries/digitisedPages/digitisedPage";

const DigitisedInventory = () => {
  let { id } = useParams();

  return (
    <Query query={DIGITISED_INVENTORY_QUERY} id={id}>
      {({ data: { digitisedPage } }) => {
        let record = digitisedPage;
        const imageUrl = smallestImageURLForRecord(record);
        //console.log("digitisedPages", digitisedPage);
        return (
          <div>
            <div
              id="banner"
              className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              <h1>{record.title}</h1>
            </div>

            <div className="uk-section">
              <div className="uk-container uk-container-small">
                <div className="uk-panel">
                  <h1 className="uk-article-title">{record.title}</h1>
                  {record.pages.length > 0 && (
                    <ul className="uk-list uk-link-text">
                      {record.pages.map((record) => {
                        const { id, title, item = [], records } = record;

                        return (
                          <li key={id}>
                            <div className="uk-flex-middle" uk-grid="true">
                              {records.length > 0 && (
                                <Link to={`/record/${records[0].id}`}>
                                  <ReactMarkdown source={title} />
                                </Link>
                              )}
                            </div>
                            {item.length > 0 && (
                              <ul className="uk-list uk-link-text uk-margin-left">
                                {item.map((item) => {
                                  return item.objetdart ? (
                                    <li key={item.id}>
                                      <b>
                                        <ReactMarkdown source={item.title} />
                                      </b>
                                      <div className="uk-panel-box">
                                        <ReactMarkdown
                                          source={item.transcript}
                                        />
                                        <div className="uk-text-right">
                                          <Link
                                            to={`/item/${item.objetdart.id}`}
                                          >
                                            View Object
                                          </Link>
                                        </div>
                                      </div>
                                    </li>
                                  ) : null;
                                })}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  <ReactMarkdown source={record.scran_id} />
                </div>
              </div>
            </div>

            <div className="uk-section uk-position-bottom-center uk-position-relative">
              {Copyright()}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default DigitisedInventory;
