import React from "react";
import Card from "../RecordCard";
import { imageURLForRecord } from "../../utils/recordUtils";

const InventoryDigestList = ({ digests }) => {
  return (
    <div>
      <div
        className="uk-child-width-expand@s uk-text-center uk-grid-column-small"
        data-uk-grid
      >
        <div>
          <div
            className="uk-child-width-1-3@s uk-child-width-1-4@m"
            data-uk-grid
          >
            {digests.map((digest) => {
              let imageURL = imageURLForRecord(digest);
              return <Card scran_id={digest.scran_id} imageUrl={imageURL} title={digest.title} link={`/inventory_digest/${digest.id}`} name={digest.name} key={`digest__${digest.id}`} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDigestList;
