import React from "react";
import { Link } from "react-router-dom";

const Copyright = () => (
  <div className="uk-padding">
    <Link
      className="uk-height-small uk-align-center uk-link-muted"
      to="/copyright"
    >
      Copyright
    </Link>
  </div>
);

export default Copyright;
