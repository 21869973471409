import React from "react";
import Query from "../Query";
import { Link } from "react-router-dom";

import CATEGORIES_QUERY from "../../queries/category/categories";

const fullScreenNavOptions = () => {
  return (
    <>
      <div className="uk-navbar-right">
        <ul className="uk-navbar-nav">
          <Query query={CATEGORIES_QUERY}>
            {({ data: { categories } }) => {
              if (!categories) return null;
              return categories.map((category, i) => {
                return (
                  <li className="uk-active" key={i}>
                    <Link to={`/${category.link}`} className="uk-link-reset">
                      {category.name}
                    </Link>
                  </li>
                );
              });
            }}
          </Query>
        </ul>
      </div>
    </>
  );
};

const mobileNavOptions = () => {
  return (
    <>
      <div className="uk-navbar-right">
        <ul className="uk-navbar-nav">
          <div>
            <a
              className="uk-navbar-toggle"
              uk-navbar-toggle-icon="true"
              href="#"
            ></a>
          </div>

          <div className="uk-navbar-dropdown">
            <Query query={CATEGORIES_QUERY}>
              {({ data: { categories } }) => {
                if (!categories) return null;
                return (
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    {categories.map((category, i) => {
                      return (
                        <li key={i}>
                          <Link
                            to={`/${category.link}`}
                            className="uk-link-reset"
                          >
                            {category.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                );
              }}
            </Query>
          </div>
        </ul>
      </div>
    </>
  );
};
const leftNavTitle = () => {
  return (
    <div className="uk-navbar-left" style={{ paddingTop: "10px" }}>
      <ul className="uk-navbar-nav">
        <li>
          <Link to="/">
            <div className="uk-visible@m">
              <h4>Hamilton Palace Archives</h4>
            </div>
            <div className="uk-hidden@m">
              <h4>VHPT</h4>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};
const Nav = () => {
  return (
    <>
      <div uk-sticky="true">
        <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
          <nav
            className="uk-navbar-container"
            data-uk-navbar="true"
            uk-navbar="dropbar: true; mode: click"
          >
            {leftNavTitle()}
            <div className="uk-navbar-right">
              <ul className="uk-navbar-nav">
                <li className="uk-active">
                  <div className="uk-visible@s">{fullScreenNavOptions()}</div>
                  <div className="uk-hidden@s">{mobileNavOptions()}</div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Nav;
