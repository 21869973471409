import React, { useState, useRef } from "react";
import DigitisedInventoriesList from "../../components/DigitisedInventoriesList";
import Query from "../../components/Query";
//import DIGITISED_INVENTORIES_QUERY from "../../queries/digitisedInventories/digitisedInventories";
import DIGITISED_INVENTORIES_QUERY from "../../queries/digitisedPages/digitisedPages";

const DigitisedInventories = (props) => {
  return (
    <div>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>Digitised Inventories</h1>
          <hr></hr>
          <Query query={DIGITISED_INVENTORIES_QUERY(0, 50, "")}>
            {({ data: { digitisedPages } }) => {
              // console.log("records: ", digitisedPages);
              let data = digitisedPages;
              //Order By Date;
              data.sort((a, b) => {
                let date1 = a.title.match(/\d/g).slice(0, 4).join("");
                let date2 = b.title.match(/\d/g).slice(0, 4).join("");
                return date1 - date2;
              });
              return <DigitisedInventoriesList records={data || []} />;
            }}
          </Query>
        </div>
      </div>
    </div>
  );
};

export default DigitisedInventories;
