import gql from "graphql-tag";
import { realationalSearchType, realationalSearchName } from '../../constants/vhpt';

const DIGITISED_PAGES_QUERY = (start, limit, search, type) => {
  let searchStr = `start : ${start}, limit : ${limit}`;

  if (search.length >= 1) {
    let searchText = search.trim();
    //searchText = searchText.replace(/"/g, "");

    if (realationalSearchType[type]) {
      searchStr += `,where : { ${realationalSearchName[type]} : {${realationalSearchType[type]} : "${searchText}" }}`;
    } else {
      searchStr += `,where : { ${realationalSearchName[type]}_contains : "${searchText}" }`;
    }
  }
  console.log(" DIGITISED_INVENTORIES_QUERY searchStr", searchStr);
  return gql`
  query digitisedPages {
    digitisedPages(${searchStr}) {
      id
      title
      image { url }
     	pages {
        id
        title
        records {
          id
          title
        }
        item {
          id
          title
          coordinates
          transcript
        }
      }
    }
  }
`;
};

export default DIGITISED_PAGES_QUERY;
