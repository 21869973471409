import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ props = {} }) => {
    const { history = { goBack: () => { } } } = props
    return (
        <div style={{ height: 50, width: 200 }}>
            <div className="uk-margin-left">
                <ul className="uk-breadcrumb">
                    <li><div onClick={() => { history.goBack(); }}>Back</div></li>
                </ul>
            </div >
        </div>
    );
};

export default Breadcrumbs;
