export const SERVERADDRESS = "https://archive.vhpt.org";
export const realationalSearchType = {
  copyrights: "name_contains",
  transcribers: "name_contains",
  projects: "name_contains",
  references: "name_contains",
  periods: "date_contains",
  materials: "name_contains",
  people: "name_contains",
  subjects: "name_contains",
  locations: "name_contains",
  tags: "tag_contains",
  dimension: "Dimension_contains",
  digitised_inventory: "name_contains",
  records: "name_contains",
  objetdarts: "name_contains",
  people_id: "id",
};

export const realationalSearchName = {
  copyrights: "copyrights",
  transcribers: "transcribers",
  projects: "projects",
  references: "references",
  periods: "periods",
  materials: "materials",
  people: "people",
  subjects: "subjects",
  locations: "locations",
  tags: "tags",
  dimension: "dimension",
  digitised_inventory: "digitised_inventory",
  records: "records",
  objetdarts: "objetdarts",
  people_id: "people",
};
