import gql from "graphql-tag";

const DIGITISED_INVENTORY_QUERY = gql`
  query digitisedPage($id: ID!) {
    digitisedPage(id: $id) {
      id
      title
      image {
        url
      }
      pages {
        id
        title
        records {
          image {
            formats
          }
          id
          title
        }
        item {
          id
          title
          coordinates
          transcript
          objetdart {
            id
          }
        }
      }
    }
  }
`;

export default DIGITISED_INVENTORY_QUERY;
