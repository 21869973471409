import gql from "graphql-tag";
import {
  realationalSearchType,
  realationalSearchName,
} from "../../constants/vhpt";

const RECORDS_QUERY = (start, limit, search, type) => {
  let searchStr = "";
  let pages = `start : ${start}, limit : ${limit}`;
  //console.log("typeL ", type);
  //console.log("search ", search);
  if (search.length >= 1) {
    let searchText = search.trim();
    //searchText = searchText.replace(/"/g, "");

    if (realationalSearchType[type]) {
      searchStr = `,where : { ${realationalSearchName[type]} : {${realationalSearchType[type]} : "${searchText}" }}`;
    } else {
      searchStr = `,where : { ${type}_contains : "${searchText}" }`;
    }
  }

  return gql`
  query Records {
    records(${pages}${searchStr}) {
      id
      name
      title
      description
      transcription
      scran_id
      image {
        url
        formats
      }
    }
  }
`;
};

export default RECORDS_QUERY;
