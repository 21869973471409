import React from "react";
import InventoryDigestList from "../../components/InventoryDigestsList";
import Query from "../../components/Query";
import INVENTORY_DIGESTS_QUERY from "../../queries/inventoryDigests/inventoryDigests";

const InventoryDigests = () => {
  return (
    <div>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>Inventory Digests</h1>
          <hr></hr>
          <Query query={INVENTORY_DIGESTS_QUERY(0, 50, "")}>
            {({ data: { inventoryDigests } }) => {
              let data = inventoryDigests;
              data.sort((a, b) => {
                let date1 = a.title.match(/\d/g).slice(0, 4).join("");
                let date2 = b.title.match(/\d/g).slice(0, 4).join("");
                return date1 - date2;
              });
              return <InventoryDigestList digests={data} />;
            }}
          </Query>
        </div>
      </div>
    </div>
  );
};

export default InventoryDigests;
