import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import { Link } from "react-router-dom";
import { largestPictureForItem } from "../../utils/recordUtils";
import PlainText from "../../components/PlainText";
import ITEM_QUERY from "../../queries/items/item";

const Item = () => {
  let { id } = useParams();
  return (
    <Query query={ITEM_QUERY} id={id}>
      {({ data: { item } }) => {
        if (!item) {
          return null;
        }

        const {
          locations = [],
          name,
          description,
          scran_id,
          website,
          dimensions,
          materials,
          people,
          records,
          copyrights,
          image,
          periods,
        } = item;
        const imageUrl = largestPictureForItem(item);

        return (
          <div>
            <div
              id="banner"
              className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-light uk-padding uk-margin uk-background-cover"
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              <h2>{name}</h2>
            </div>

            <div className="uk-container uk-container-center uk-margin-top uk-margin-large-bottom">
              <div uk-grid="true" data-uk-grid-margin="true">
                <div className="uk-width-medium-3-4 uk-row-first">
                  <article className="uk-article">
                    <h1 className="uk-article-title">{name}</h1>
                    <hr className="uk-divider-icon"></hr>

                    <PlainText source={description} />
                    <hr className="uk-divider-icon"></hr>
                  </article>
                </div>
                <div className="uk-width-medium-1-4 ">
                  <div className="uk-panel uk-panel-box uk-text-center">
                    {image && (
                      <div
                        uk-lightbox="animation: fade"
                        data-lightbox-type="image"
                        uk-tooltip="Show full screen"
                      >
                        <a
                          uk-lightbox="animation: fade"
                          href={imageUrl}
                          className="uk-margin-small-right"
                          data-lightbox-type="image"
                          title=""
                        >
                          <img
                            width="120"
                            height="120"
                            src={imageUrl}
                            alt=""
                            style={{ height: 220, width: "auto" }}
                          />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="uk-panel" style={{ marginTop: 20 }}>
                    <h3 className="uk-panel-title">Details</h3>
                    <ul className="uk-list uk-list-line">
                      <li>Website: {website}</li>
                      <li>
                        Scran id: <br />
                        <Link
                          to={{
                            pathname: `/items`,
                            state: { search: scran_id, type: "scran_id" },
                          }}
                        >
                          {scran_id}
                        </Link>
                      </li>
                      <li>
                        Image Copyright:
                        <br />
                        {copyrights.map((copyright) => (
                          <Link
                            to={{
                              pathname: `/records`,
                              state: {
                                search: copyright.name,
                                type: "copyrights",
                              },
                            }}
                          >
                            {copyright.name}
                          </Link>
                        ))}
                      </li>

                      <li>
                        Locations:
                        <br />
                        {locations.map((s) => (
                          <Link
                            to={{
                              pathname: `/records`,
                              state: {
                                search: s.name,
                                type: "locations",
                              },
                            }}
                          >
                            {s.name}
                            <br />
                          </Link>
                        ))}
                      </li>
                      <li>
                        People:
                        <br />
                        {people.map((s) => (
                          <Link
                            to={{
                              pathname: `/people`,
                              state: {
                                search: s.name,
                                type: "people",
                              },
                            }}
                          >
                            {s.name}
                            <br />
                          </Link>
                        ))}
                      </li>
                      <li>
                        <div>Records: </div>
                        {records &&
                          records.length > 0 &&
                          records.map((record) => {
                            // console.log("record: ", record);
                            const { id, title } = record;
                            return (
                              <div>
                                <Link to={`/record/${id}`}>{title}</Link>
                              </div>
                            );
                          })}
                      </li>
                      <li>
                        Materials:
                        <br />
                        {materials && materials.map((s) => s.type)}
                      </li>
                      <li>
                        Periods:
                        <br />
                        {periods &&
                          periods.map((s) => (
                            <Link
                              to={{
                                pathname: `/records`,
                                state: {
                                  search: s.date,
                                  type: "periods",
                                },
                              }}
                            >
                              {s.date}
                              <br />
                            </Link>
                          ))}
                      </li>
                      <li>
                        Dimension:
                        <br />
                        {dimensions &&
                          dimensions.map((s) => (
                            <>
                              {s.Dimension}
                              <br />
                            </>
                          ))}
                      </li>

                      <li>Record id:{item.id}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default Item;
