import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Copyright from "../../components/Copyright";
import {
  smallestImageURLForRecord,
  downloadLinkDetails,
} from "../../utils/recordUtils";
import PlainText from "../../components/PlainText";
import PERSON_QUERY from "../../queries/people/person";

const Record = (props) => {
  let { id } = useParams();

  return (
    <Query query={PERSON_QUERY} id={id}>
      {({ data: { person } }) => {
        const imageUrl = smallestImageURLForRecord(person);

        return (
          <>
            <div className="uk-container uk-container-center uk-margin-top uk-margin-large-bottom">
              <div uk-grid="true" data-uk-grid-margin="true">
                <div className="uk-width-medium-3-4 uk-row-first">
                  <article className="uk-article animation: uk-animation-fade">
                    <h1 className="uk-article-title">{person.name}</h1>
                    <hr></hr>
                    <div className="uk-section">
                      <div className="uk-container uk-container-small">
                        <div className="uk-panel">
                          <Link to={`/imageview/${id}`}>
                            <img
                              src={imageUrl}
                              alt={imageUrl}
                              className="uk-height-small uk-align-left uk-margin-remove-adjacent"
                            />
                          </Link>

                          <PlainText source={person.information} />
                        </div>
                      </div>
                    </div>
                  </article>
                  <hr></hr>
                </div>

                <div className="uk-width-medium-1-4 animation: uk-animation-fade">
                  <div className="uk-panel uk-margin-top">
                    <h3 className="uk-panel-title">Details</h3>
                    <ul className="uk-list uk-list-line">
                      <li>
                        <text>Date of birth: </text>
                        {person.dob}
                      </li>
                      <li>
                        <text>Date of death: </text>
                        {person.dod}
                      </li>
                      <li>
                        <text>
                          Records:
                          <br />
                        </text>

                        <Link
                          to={{
                            pathname: `/records`,
                            state: {
                              search: person.id,
                              type: "people_id",
                            },
                          }}
                        >
                          {person.name}
                          <br />
                        </Link>
                      </li>

                      <li>
                        <text>
                          Objet D'art: <br />
                        </text>

                        <Link
                          to={{
                            pathname: `/items`,
                            state: {
                              search: person.id,
                              type: "people_id",
                            },
                          }}
                        >
                          {person.name}
                          <br />
                        </Link>
                      </li>

                      <li>
                        <text>Record id: </text>
                        {person.id}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="uk-section uk-position-bottom-center uk-position-relative">
                {Copyright()}
              </div>
            </div>
          </>
        );
      }}
    </Query>
  );
};

export default Record;
