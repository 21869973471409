import gql from "graphql-tag";

const ITEM_QUERY = gql`
  query Item($id: ID!) {
    item(id: $id) {
      id
      name
      description
      website
      dimensions {
        id
        Dimension
      }
      copyrights {
        id
        name
      }
      materials {
        id
        type
      }
      scran_id
      locations {
        name
        description
      }
      people {
        id
        name
      }
      periods {
        id
        date
      }
      records {
        id
        name
        title
      }
      image {
        url
        formats
      }
      locations {
        id
        name
      }
    }
  }
`;

export default ITEM_QUERY;
