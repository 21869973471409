import gql from "graphql-tag";

const PEOPLE_QUERY = (start, limit, search, type) => {
  return gql`
    query People {
      people {
        id
        name
        information
        dob
        dod
        image {
          id
          url
          formats
        }
      }
    }
  `;
};
export default PEOPLE_QUERY;
