import React from "react";
import Card from "../PeopleCard";
import { largestPictureForItem } from "../../utils/recordUtils";

const PeopleList = ({ people }) => {
    return (
        <div>
            <div
                className="uk-child-width-expand@s uk-text-center uk-grid-column-small"
                data-uk-grid
            >
                <div>
                    <div
                        className="uk-child-width-1-3@s uk-child-width-1-4@m"
                        data-uk-grid
                    >
                        {people.map((person, i) => {
                            const imageUrl = largestPictureForItem(person);

                            return (
                                <Card
                                    imageUrl={imageUrl}
                                    name={person.name}
                                    id={person.id}
                                    key={`person__${i}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PeopleList;
