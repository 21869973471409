import gql from "graphql-tag";

const INVENTORY_DIGESTS_QUERY = (start, limit, search, type) => {
  let searchStr = `start : ${start}, limit : ${limit}`;

  if (search.length >= 2) {
    let searchText = search.trim();
    searchStr += `,where : { ${type}_contains : "${searchText}" }`;
  }
  return gql`
  query InventoryDigests {
    inventoryDigests(${searchStr}) {
      id
      title
      introduction
      information
      records { id title }
      digitised_pages { id title pages { id title }}
      scran_id
      image {
        url
        formats
      }
    }
  }
`;
};

export default INVENTORY_DIGESTS_QUERY;
