import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Copyright from "../../components/Copyright";
import { smallestImageURLForRecord } from "../../utils/recordUtils";

import INVENTORY_DIGEST_QUERY from "../../queries/inventoryDigests/inventoryDigest";

const InventoryDigest = () => {
  let { id } = useParams();

  return (
    <Query query={INVENTORY_DIGEST_QUERY} id={id}>
      {({ data: { inventoryDigest } }) => {
        const { title, introduction, information, scran_id } = inventoryDigest;
        let records = inventoryDigest.digitised_pages;
        const imageUrl = smallestImageURLForRecord(inventoryDigest);
        // console.log("inventoryDigest", inventoryDigest);
        return (
          <div>
            <div
              id="banner"
              className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              <h1>{title}</h1>
            </div>

            <div className="uk-section">
              <div className="uk-container uk-container-small">
                <div className="uk-panel">
                  <h1 className="uk-article-title">{title}</h1>
                  <ReactMarkdown source={scran_id} />
                  <hr></hr>
                  <ReactMarkdown source={introduction} />
                  <hr></hr>
                  <ReactMarkdown source={information} />
                  {records &&
                    records.length > 0 &&
                    records.map((record) => (
                      <>
                        <hr></hr>
                        <ul className="uk-list uk-link-text">
                          {record.pages.map((record) => {
                            const {
                              id,
                              title,
                              name,
                              item = [],
                              records,
                            } = record;

                            return (
                              <li key={id}>
                                <div className="uk-flex-middle" uk-grid="true">
                                  {records.length > 0 &&
                                    records.map((record) => (
                                      <>
                                        <Link to={`/record/${record.id}`}>
                                          <ReactMarkdown
                                            source={record.title || record.name}
                                          />
                                        </Link>
                                        <img
                                          src={smallestImageURLForRecord(
                                            record
                                          )}
                                          alt="record iamge"
                                        />
                                      </>
                                    ))}
                                </div>
                                {item.length > 0 && (
                                  <ul className="uk-list uk-link-text uk-margin-left">
                                    {item.map((item) => {
                                      return item.objetdart ? (
                                        <li key={item.id}>
                                          <div className="uk-panel-box">
                                            <ReactMarkdown
                                              source={item.title}
                                            />
                                            <ReactMarkdown
                                              source={item.transcript}
                                            />
                                            <div className="uk-text-right">
                                              <Link
                                                to={`/item/${item.objetdart.id}`}
                                              >
                                                View Object
                                              </Link>
                                            </div>
                                          </div>
                                        </li>
                                      ) : null;
                                    })}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ))}
                </div>
              </div>
            </div>

            <div className="uk-section uk-position-bottom-center uk-position-relative">
              {Copyright()}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default InventoryDigest;
