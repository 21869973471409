import React from "react";
import marked from "marked";
import DOMPurify from "dompurify";

const PlainText = ({ source }) => {
  if (!source || source === null) {
    return <></>;
  }
  const formattedText = marked(source, { breaks: true, gfm: true });
  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      <div
        contentEditable="false"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(formattedText),
        }}
      ></div>
    </div>
  );
};

export default PlainText;
