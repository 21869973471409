import React, { useState, useRef } from "react";
import { useParams } from "react-router";
import ItemsList from "../../components/Items";
import Query from "../../components/Query";
import ITEMS_QUERY from "../../queries/items/items";

let data = [];

const searchBar = (
  search,
  setSearch,
  searchTypeRef,
  searchType,
  setSearchType,
  setIndex
) => {
  return (
    <div className="uk-position-top-right uk-position-relative uk-width-1-2 uk-search uk-search-default">
      <input
        className="uk-position-left uk-position-relative uk-width-1-2 uk-search"
        type="search"
        placeholder="Search..."
        onChange={(evt) => {
          setSearch(evt.target.value);
          setIndex(0);
        }}
        value={search}
      ></input>
      <span> In </span>
      <select
        id="searchType"
        className="uk-position-right uk-position-relative uk-width-small"
        ref={searchTypeRef}
        value={searchType || "name"}
        onChange={(evt) => setSearchType(evt.target.value)}
      >
        <option value="name">Name</option>
        <option value="description">Description</option>
        <option value="scran_id">Scran Id</option>
        <option value="copyrights">Copyrights</option>
        <option value="id">Id</option>
      </select>
      <button
        onClick={() => {
          setSearch("");
          setSearchType("name");
          setIndex(0);
        }}
      >
        clear
      </button>
    </div>
  );
};

const Items = (props) => {
  // console.log("props: ", props);
  let { search = "", type } = props.location.state || {};
  const searchTypeRef = useRef();
  const [index, setIndex] = useState(0);
  const [limit, setLimit] = useState(100);
  const [searchStr, setSearch] = useState(search);
  const [searchType, setSearchType] = useState(
    type || (searchTypeRef.current || {}).value || "name"
  );

  console.log("searcj: ", searchStr);

  return (
    <div>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>Objet D'art</h1>
          <div className="uk-padding uk-flex">
            {searchBar(
              searchStr,
              setSearch,
              searchTypeRef,
              searchType,
              setSearchType,
              setIndex
            )}
          </div>
          <hr></hr>
          <Query query={ITEMS_QUERY(index, limit, searchStr, searchType)}>
            {({ data: { items } }) => {
              data = items;
              return <ItemsList items={data} />;
            }}
          </Query>
          <hr></hr>
        </div>
      </div>
    </div>
  );
};

export default Items;
