import React from "react";
import imageUrl from "../../assets/images/imagefade.jpg";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <div className="uk-container uk-container-center uk-margin-top uk-margin-large-bottom">
        <div uk-grid="true" data-uk-grid-margin="true">
          <div className="uk-width-medium-3-4 uk-row-first animation: uk-animation-fade">
            <h1>Hamilton Palace Inventories</h1>
            <a href="/records">
              <div
                className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-contain uk-light uk-padding uk-margin"
                style={{ backgroundImage: `url(${imageUrl})` }}
              ></div>
            </a>
            <article class="uk-article animation: uk-animation-fade">
              <p>
                In association with the National Archives of Scotland, the Trust
                has now embarked on the online publication of the important
                historical inventories of the contents of the Palace from the
                seventeenth to the nineteenth centuries. This will provide an
                important research tool for historians, curators of galleries
                and museums, fine art dealers, as well a scholars and students
                in Scotland and many parts of the world.
              </p>
              <p>
                The long term aim is to make the inventories available in
                database format with links to locations, images, sale catalogues
                and other related archive material. As well as digital images of
                the pages of the inventories themselves, there will be
                transcripts in both contemporary and modern versions. The Trust
                is also exploring ways as to how the database can eventually be
                added to by scholars and art historians, becoming an
                international collaborative venture.
              </p>
            </article>
          </div>

          <div className="uk-width-medium-1-4 ">
            <Link to="/digitised_inventories" className=" uk-link-text">
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                Digitised Inventories
              </div>
            </Link>
            <Link to="/inventory_digests" className=" uk-link-text">
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                Inventory Digest
              </div>
            </Link>
            <Link to="/records" className=" uk-link-text">
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                Records
              </div>
            </Link>
            <Link to="/items" className=" uk-link-text">
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                Objet D'art
              </div>
            </Link>
            <Link to="/people" className=" uk-link-text">
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                People
              </div>
            </Link>
            <a href="https://vhpt3d.web.app" className=" uk-link-text">
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                Virtual Hamilton Palace
              </div>
            </a>
            <a
              href="https://vhpt3d.web.app/contact.html"
              className=" uk-link-text"
            >
              <div className="uk-panel uk-panel-box uk-text-center uk-margin-top">
                Contact VHPT
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
