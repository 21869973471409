import gql from "graphql-tag";

const PERSON_QUERY = gql`
  query Person($id: ID!) {
    person(id: $id) {
      id
      name
      information
      dob
      dod
      image {
        id
        url
        formats
      }
    }
  }
`;

export default PERSON_QUERY;
