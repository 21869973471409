import React from "react";

const Spinner = ({ ratio = 3.5 }) => (
  <div className="uk-flex uk-margin">
    <div className="uk-padding">
      <div className="uk-container uk-container-large">
        <div className="uk-position-center">
          <span uk-spinner={`ratio: ${ratio}`}></span>
        </div>
      </div>
    </div>
  </div>
);

export default Spinner;
