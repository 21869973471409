import React, { useState, useRef } from "react";
import RecordsList from "../../components/Records";
import Query from "../../components/Query";
import RECORDS_QUERY from "../../queries/records/records";

let data = [];

const pageButtons = (
  index,
  limit,
  setIndex,
  setLimit,
  search,
  setSearch,
  count
) => (
  <div className="uk-width-1-1@s uk-width-1-2@m">
    <div className="uk-position-top-center uk-position-relative">
      <ul className="uk-pagination" uk-margin="true">
        <li className={index - 1 < 0 ? "uk-disabled" : "uk-active"}>
          <a
            href="#"
            onClick={() => setIndex(index - limit < 0 ? 0 : index - limit)}
          >
            <span uk-pagination-previous="true"></span>
          </a>
        </li>
        <li className={index - 1 < 0 ? "uk-disabled" : "uk-active"}>
          <a
            href="#"
            onClick={() => setIndex(index - limit < 0 ? 0 : index - limit)}
          >
            <span>Prev</span>
          </a>
        </li>
        <li>
          <span>......</span>
        </li>

        <li className={count < limit ? "uk-disabled" : "uk-active"}>
          <a href="#" onClick={() => setIndex(index + limit)}>
            <span>Next Page</span>
          </a>
        </li>

        <li className={count < limit ? "uk-disabled" : "uk-active"}>
          <a href="#" onClick={() => setIndex(index + limit)}>
            <span uk-pagination-next="true"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
);

const searchBar = (
  search,
  setSearch,
  searchTypeRef,
  searchType,
  setSearchType,
  setIndex
) => {
  return (
    <div className="uk-position-top-right uk-position-relative uk-width-1-1@s uk-width-1-2@m uk-search uk-search-default">
      <input
        className="uk-position-left uk-position-relative uk-width-1-2 uk-search"
        type="search"
        placeholder="Search..."
        onChange={(evt) => {
          setSearch(evt.target.value);
          setIndex(0);
        }}
        value={search}
      ></input>
      <span> In </span>
      <select
        id="searchType"
        className="uk-position-right uk-position-relative uk-width-small"
        ref={searchTypeRef}
        value={searchType || "description"}
        onChange={(evt) => setSearchType(evt.target.value)}
      >
        <option value="description">Description</option>
        <option value="transcription">Transcription</option>
        <option value="tags">Tags</option>
        <option value="metadata">Metadata</option>
        <option value="periods">Dates</option>
        <option value="locations">Location</option>
        <option value="scran_id">Scran Id</option>
        <option value="copyrights">Copyrights</option>
        <option value="transcribers">Transcriber</option>
      </select>
      <button
        onClick={() => {
          setSearch("");
          setSearchType("description");
          setIndex(0);
        }}
      >
        clear
      </button>
    </div>
  );
};

const Records = (props) => {
  let { search = "", type } = props.location.state || {};
  const searchTypeRef = useRef();
  const [index, setIndex] = useState(0);
  const [limit, setLimit] = useState(50);
  const [searchStr, setSearch] = useState(search);
  const [searchType, setSearchType] = useState(
    type || (searchTypeRef.current || {}).value || "name"
  );
  const [count, setCount] = useState(1);

  const pageNav = pageButtons(
    index,
    limit,
    setIndex,
    setLimit,
    searchStr,
    setSearch,
    count
  );

  return (
    <div>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>Records</h1>
          <p>
            The Trust has now embarked on the online publication of the
            important historical inventories of the contents of the Palace from
            the seventeenth to the nineteenth centuries. There are over{" "}
            <b>2000</b> records available to view online.
          </p>
          <div className="uk-padding uk-width-1-1" uk-grid="true">
            {pageNav}
            {searchBar(
              searchStr,
              setSearch,
              searchTypeRef,
              searchType,
              setSearchType,
              setIndex
            )}
          </div>
          <hr></hr>
          <Query query={RECORDS_QUERY(index, limit, searchStr, searchType)}>
            {({ data: { records } }) => {
              data = records;
              if (data) {
                setCount(data.length);
              }
              //  console.log("data: ", q);
              return <RecordsList records={records} />;
            }}
          </Query>
          <hr></hr>
        </div>
        <div className="uk-padding  uk-width-1-1" uk-grid="true">
          {pageNav}
        </div>
      </div>
    </div>
  );
};

export default Records;
