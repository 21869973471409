import gql from "graphql-tag";

const RECORD_QUERY = gql`
  query Record($id: ID!) {
    record(id: $id) {
      id
      name
      title
      description
      scran_id
      transcription
      downloads {
        id
        url
        name
      }
      metadata
      copyrights {
        id
        name
      }
      projects {
        id
        name
      }
      references {
        id
        name
      }
      periods {
        id
        date
      }
      materials {
        id
        type
      }
      people {
        id
        name
      }
      subjects {
        id
        name
      }
      locations {
        id
        name
      }
      tags {
        id
        tag
      }
      dimension {
        id
        Dimension
      }
      digitised_inventory {
        id
        name
      }
      digitised_page {
        id
        title
      }
      records {
        id
        name
      }
      image_orientation {
        id
      }
      objetdarts {
        id
        name
      }
      image {
        url
        formats
      }
      transcribers {
        id
        name
        avatar {
          url
        }
      }
    }
  }
`;

export default RECORD_QUERY;
