import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import Magnifier from "react-magnifier";
import RECORD_QUERY from "../../queries/records/record";
import * as loadImage from "blueimp-load-image";
import Spinner from "../../components/Spinner";
import { largestImageURLForRecord } from "../../utils/recordUtils";
import Breadcrumbs from '../../components/Breadcrumb';

const switchFilter = (imageRef, filter) => {
  if (imageRef.current.classList.contains(filter)) {
    imageRef.current.classList.remove(filter);
  } else {
    imageRef.current.classList.add(filter);
  }
};

const effectsList = [
  { id: "img-effect-sepia", name: "Sepia" },
  { id: "img-effect-gray", name: "Grayscale" },
  { id: "img-effect-invert", name: "Inverted" },
  { id: "img-effect-saturate", name: "Saturate" },
];

const filterButtons = (imageRef, imageUrl, onChangeRotation, onChangeZoom) => (
  <>
    <div className="uk-flex">
      <button
        className="uk-button uk-button-default uk-margin-small-right"
        type="button"
        uk-toggle="target: #offcanvas-usage"
        uk-icon="icon: settings"
        uk-tooltip="Adjust image settings"
      ></button>

      <div
        uk-lightbox="animation: fade"
        data-lightbox-type="image"
        uk-tooltip="Show full screen"
      >
        <a
          uk-lightbox="animation: fade"
          href={imageUrl}
          className="uk-button uk-button-default uk-margin-small-right"
          data-lightbox-type="image"
          title=""
        >
          <div uk-icon="icon: desktop" data-lightbox-type="image"></div>
        </a>
      </div>
    </div>
    <div id="offcanvas-usage" uk-offcanvas="true">
      <div className="uk-offcanvas-bar">
        <button
          className="uk-offcanvas-close"
          type="button"
          uk-close="true"
        ></button>

        <h3>Filters</h3>

        <p>
          You can apply filters to the image to help with reading and
          translation.
        </p>
        <ul className="uk-list">
          {effectsList.map((effect) => (
            <li key={effect.id}>
              <input
                type="checkbox"
                onClick={() => switchFilter(imageRef, effect.id)}
              ></input>
              <label> {` ${effect.name}`}</label>
            </li>
          ))}
        </ul>
        <form>
          <p>Image Orientation</p>
          <select
            className="uk-select"
            onChange={(e) => {
              onChangeRotation(e.target.value);
              e.stopPropagation();
            }}
          >
            <option value="-1">undefined: Browser default</option>
            <option value="0">true: Automatic</option>
            <option value={1}>1: Original</option>
            <option value={2}>2: Horizontal flip</option>
            <option value={3}>3: Rotate 180° CCW</option>
            <option value={4}>4: Vertical flip</option>
            <option value={5}>5: Vertical flip + Rotate 90° CW</option>
            <option value={6}>6: Rotate 90° CW</option>
            <option value={7}>7: Horizontal flip + Rotate 90° CW</option>
            <option value={8}>8: Rotate 90° CCW</option>
          </select>
          <p>Magnifier Zoom</p>
          <input
            className="uk-range"
            type="range"
            min={1.1}
            max={3.0}
            step={0.1}
            onChange={(evt) => {
              onChangeZoom(evt.target.value);
              evt.stopPropagation();
            }}
          />
        </form>
      </div>
    </div>
  </>
);

const ImageView = (props) => {
  //  console.log("props!: ", props);
  const [loading, setLoading] = useState(true);
  const [url, setURL] = useState("");
  const [orientation, setOrientation] = useState(0);
  const [zoom, setZoom] = useState(1.5);

  let { id } = useParams();
  let imageRef = useRef();
  const { data } = Query({
    children: (data) => data,
    query: RECORD_QUERY,
    id: id,
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const { record } = data;

    let imageUrl = largestImageURLForRecord(record);
    loadImage(
      imageUrl,
      (canvas) => {
        try {
          let updatedImage = canvas.toDataURL("image/jpeg");
          setURL(updatedImage);
          setLoading(false);
        } catch (err) {
          console.log("err converting image so lets revert to src");
          setURL(imageUrl);
          setLoading(false);
        }
      },
      { orientation: Number(orientation) }
    );
  });

  let imageUrl = "";
  if (data) {
    const { record } = data;
    imageUrl = largestImageURLForRecord(record);
  }

  return (
    <div>

      <div>

        <div className="uk-padding">

          <Breadcrumbs props={props} />

          {filterButtons(
            imageRef,
            imageUrl,
            (value) => {
              setOrientation(value);
            },
            (value) => {
              setZoom(value);
            }
          )}
        </div>
        {(!data || loading) && (
          <div className="uk-position-center uk-position-relative">
            <Spinner />
          </div>
        )}
        {data && !loading && (
          <div ref={imageRef} className="uk-container uk-container-large">
            <Magnifier
              mgShape="square"
              src={url}
              width="auto"
              zoomFactor={zoom}
              key={imageUrl}
            />
          </div>
        )}
      </div>
      <canvas id="canvas" />
    </div>
  );
};

export default ImageView;
