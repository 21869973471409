import { SERVERADDRESS } from "../constants/vhpt";
//const noImage = require("../assets/images/noimage.jpg");
export const noPicture =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjQsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOTAwcHgiIGhlaWdodD0iMzAwcHgiIHZpZXdCb3g9IjAgMCA5MDAgMzAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA5MDAgMzAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IGZpbGw9IiNGNUY1RjUiIHdpZHRoPSI5MDAiIGhlaWdodD0iMzAwIi8+DQo8ZyBvcGFjaXR5PSIwLjciPg0KCTxwYXRoIGZpbGw9IiNEOEQ4RDgiIGQ9Ik0zNzguMTg0LDkzLjV2MTEzaDE0My42MzN2LTExM0gzNzguMTg0eiBNNTEwLjI0NCwxOTQuMjQ3SDM5MC40Mzd2LTg4LjQ5NGgxMTkuODA4TDUxMC4yNDQsMTk0LjI0Nw0KCQlMNTEwLjI0NCwxOTQuMjQ3eiIvPg0KCTxwb2x5Z29uIGZpbGw9IiNEOEQ4RDgiIHBvaW50cz0iMzk2Ljg4MSwxODQuNzE3IDQyMS41NzIsMTU4Ljc2NCA0MzAuODI0LDE2Mi43NjggNDYwLjAxNSwxMzEuNjg4IDQ3MS41MDUsMTQ1LjQzNCANCgkJNDc2LjY4OSwxNDIuMzAzIDUwNC43NDYsMTg0LjcxNyAJIi8+DQoJPGNpcmNsZSBmaWxsPSIjRDhEOEQ4IiBjeD0iNDI1LjQwNSIgY3k9IjEyOC4yNTciIHI9IjEwLjc4NyIvPg0KPC9nPg0KPC9zdmc+DQo=";

const noImage = noPicture;

function urlForWindowSize(formats) {
  let width = window.innerWidth;
  const {
    thumbnail = {},
    tiny = {},
    small = {},
    medium = {},
    large = {},
    xlarge = {},
  } = formats;
  let url;

  if (width >= 0) url = thumbnail.url;
  if (width >= 298) url = small.url;
  if (width >= 398) url = medium.url;
  if (width >= 998) url = large.url;
  if (width >= 1398) url = xlarge.url;

  if (!url)
    url =
      xlarge.url ||
      large.url ||
      medium.url ||
      small.url ||
      tiny.url ||
      thumbnail.url;
  return url;
}

export const largestPictureForItem = (item) => {
  if (!item || !item.image || !item.image[0]) return noPicture;
  //console.log("item.image.formats:", item.image);
  if (item.image[0].formats) {
    return SERVERADDRESS + urlForWindowSize(item.image[0].formats);
  }
  return SERVERADDRESS + item.image[0].url;
};
export const largestPictureForRecord = (image) => {
  if (image === noImage) {
    return noPicture;
  }
  return image;
};

export const largestImageURLForRecord = (record) => {
  if (!record || !record.image) return noImage;
  const { formats, url } = record.image;
  if (!formats && !url) return noImage;
  if (!formats) {
    return SERVERADDRESS + url;
  }
  const largest =
    formats.xxlarge ||
    formats.xlarge ||
    formats.large ||
    formats.medium ||
    formats.small ||
    formats.thumbnail;
  return SERVERADDRESS + largest.url;
};

export const smallestImageURLForRecord = (record) => {
  if (!record || !record.image) return noImage;
  const { formats, url } = record.image;
  if (!formats && !url) return noImage;
  if (!formats) {
    return SERVERADDRESS + url;
  }
  const smallest =
    formats.thumbnail ||
    formats.small ||
    formats.medium ||
    formats.large ||
    formats.xlarge ||
    formats.xxlarge ||
    url;
  return SERVERADDRESS + smallest.url;
};

export const imageURLForRecord = (record) => {
  if (!record || !record.image) return noImage;
  const { formats, url } = record.image;
  if (!formats && !url) return noImage;
  if (!formats) {
    return SERVERADDRESS + url;
  }
  const image =
    formats.small ||
    formats.medium ||
    formats.large ||
    formats.thumbnail ||
    url;
  return SERVERADDRESS + image.url;
};

export const downloadLinkDetails = (url) => {
  if (!url) {
    return;
  }
  return SERVERADDRESS + url;
};

export const srcSetFromItem = (item, url) => {
  if (!item || !item.image || !item.image[0]) return url;
  const { formats = {} } = item.image[0];
  return formatSrcSet(formats, url);
};

export const srcSetFromRecord = (record) => {
  if (!record || !record.image) return noImage;
  const { formats = {}, url } = record.image;
  return formatSrcSet(formats, url);
};

const formatSrcSet = (formats, url) => {
  const srcset = Object.values(formats).map((format) => {
    return `${SERVERADDRESS + format.url} ${format.width}w`;
  });
  if (url) {
    srcset.push(`${SERVERADDRESS + url} ${2000}w`);
  }
  console.log("srcset: ", srcset);
  return srcset.join(",");
};
