import React from "react";
import Card from "../ItemCard";
import { largestPictureForItem } from "../../utils/recordUtils";

const Items = ({ items }) => {
  return (
    <div>
      <div
        className="uk-child-width-expand@s uk-text-center uk-grid-column-small"
        data-uk-grid
      >
        <div>
          <div
            className="uk-child-width-1-3@s uk-child-width-1-4@m"
            data-uk-grid
          >
            {items.map((item, i) => {
              const imageUrl = largestPictureForItem(item);

              return (
                <Card
                  imageUrl={imageUrl}
                  title={item.name}
                  id={item.id}
                  key={`record__${i}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Items;
